import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { Container, Row, Col } from 'react-bootstrap'
import GalleryGrid from '../components/GalleryGrid/GalleryGrid'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

function ServicesPage({data, pageContext}) {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const page = data.allContentfulPage.nodes[0];
  const meta = page.meta;
    return (
        <Layout>
            <Seo title = {meta.title} description = {meta.description} keywords={meta.keywords} image = {meta.image.file.url}/>
            <Container className='content' fluid='xl'>
                <Row noGutters={true}>
                    <Col >
                      <Breadcrumb
                        crumbs={crumbs}
                        crumbLabel={page.title}
                      />
                        <h1 className="page--heading">{page.title}</h1>
                        <p className="page--desc" dangerouslySetInnerHTML={{__html: page.longDescription.childMarkdownRemark.html}}/>
                    </Col>
                </Row>
            </Container>
            <GalleryGrid
                items = {data.allContentfulServicePage.nodes}
            />
        </Layout>
    )
}
export default ServicesPage

export const servicesPageQuery = graphql`
query servicesPageQuery {
      allContentfulPage(filter: {title: {eq: "Услуги"}}) {
        nodes {
          title
          longDescription {
            childMarkdownRemark {
              html
            }
          }
          meta {
            title
            description
            keywords
            image {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulServicePage {
        nodes {
          title
          slug
          pageDescription
          heroImage {
            fluid(maxWidth: 800) {
              base64
              tracedSVG
              aspectRatio
              srcWebp
              srcSetWebp
            }
          }
        }
      }
}
`